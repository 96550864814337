<template>
      <b-card border-variant="secondary" :class="`d-flex content-card-results ${border} p-1`">
        <b-avatar :size="size_avatar" variant="ligth" square :src="img_general" class="mb-1"/>
        <span class="d-flex title-results mb-1">
          {{ title }}
        </span>
        <span class="d-flex subtitle-results">
          {{ subtitle }}
        </span>
        <b-button 
         :disabled="disabled_btn"
          @click="btn_action" 
          :class="class_btn" 
          :variant="class_btn"
          v-if="show_button"
        >
          <b-icon v-if="show_icon" :icon="icon"></b-icon>
          {{ text_button }}
        </b-button>
        <b-button 
          :disabled="disabled_second_btn"
          @click="btn_second_action" 
          class="class_btn ml-1" 
          variant="class_btn"
          v-if="show_second_button"
        >
          <b-icon v-if="show_second_icon" :icon="icon_second"></b-icon>
          {{ text_second_button }}
       </b-button>
      </b-card>
</template>
      
<script>
import { BCard, BAvatar, BButton, BIcon,} from "bootstrap-vue";

export default {
  props: {
    img_general: {
      type: String,
      default: require('@/assets/images/no_results.svg') 
    },
    class_btn: {
      type: String,
      default: "button-go"
    },
    border: {
      type: String,
      default: "border-card-results"
    },
    size_avatar: {
      type: String,
      default: "100px"
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    text_button: {
      type: String,
    },
    text_second_button: {
      type: String,
    },
    icon: {
      type: String,
    },
    icon_second: {
      type: String,
    },
    btn_action: {
      type: Function,
    },
    btn_second_action: {
      type: Function,
    },
    show_button: {
      type: Boolean,
      default: true,
    },
    show_second_button: {
      type: Boolean,
      default: false,
    },
    disabled_btn: {
      type: Boolean,
      default: false,
    },
    disabled_second_btn: {
      type: Boolean,
      default: false,
    },
    show_second_icon: {
      type: Boolean,
      default: false,
    },
    show_icon: {
      type: Boolean,
      default: false,
    },
  },
  name: "noResults",
  components: {
    BIcon,
    BButton,
    BAvatar,
    BCard,
  },
};
</script>
      
<style>
.button-go {
  margin-top: 10px;
  border-color: rgba(222,226,230,.7);
  background: transparent;
  color: #495057;
  font-weight: 400;
}
.button-go:hover {
  margin-top: 10px;
  border-color: rgba(222,226,230,.7);
  background: transparent;
  color: #212529 !important;
  font-weight: 400;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 14px 0px;
}
.subtitle-results {
  color: #495057;
}
.title-results {
  color: #212529;
  font-weight: bold;
  justify-content: center !important;
}
.border-card-results {
  border-color: rgba(222,226,230,.7) !important;
}
.content-card-results {
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
}
</style>